<template>
  <b-card-actions
    ref="cardAction"
    title="Filters"
    action-collapse
    :collapsed="true"
    class="card-filters"
  >
    <slot name="filters" />
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
  },
}
</script>

<style>
.card-filters .card-title {
  font-size: 1.07rem;
}
</style>
