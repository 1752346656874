<template>
  <div>
    <customers-filters
      :name-filter.sync="nameFilter"
      :address-filter.sync="addressFilter"
    />

    <table-list
      :fields="fields"
      module="customers"
      :show-search="true"
      :sidebar-component="UserListAddNew"
      sidebar-button="Add Customer"
    >
      <template v-slot:newsletter="data">
        <div v-if="data.item.newsletter === true">
          <feather-icon
            icon="MailIcon"
            class="text-success"
          />
        </div>
        <div v-else>
          <feather-icon
            icon="XCircleIcon"
            class="text-danger"
          />
        </div>
      </template>
      <!-- Column: User -->
      <template v-slot:name="data">
        <b-media vertical-align="center">
          <template #aside />
          <b-link
            :to="{ name: 'customers-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <b-avatar
              :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
              size="30px"
              rounded-circle
            />
            {{ data.item.firstName }}
            {{ data.item.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template v-slot:actions>
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
        </b-dropdown>
      </template>
    </table-list>
  </div>
</template>

<script>
import {
  BMedia, BLink, BDropdown, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import TableList from '@/views/components/TableList.vue'
import useUsersList from './useUsersList'
import customerStoreModule from './customerStoreModule'
import CustomersFilters from './CustomersFilters.vue'

const UserListAddNew = () => import('./UserListAddNew.vue')

export default {
  components: {
    TableList,
    BMedia,
    BLink,
    BDropdown,
    BAvatar,
    CustomersFilters,
  },
  data() {
    return {
      UserListAddNew,
    }
  },
  setup() {
    const USER_CUSTOMER_STORE_MODULE_NAME = 'customer'

    // Register module
    if (!store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.registerModule(USER_CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(USER_CUSTOMER_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.customer.tableFields)

    const {
      nameFilter,
      addressFilter,
    } = useUsersList()

    return {
      nameFilter,
      addressFilter,

      avatarText,
      fields,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
