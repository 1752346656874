<template>
  <filters>
    <div slot="filters">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Naam</label>
          <b-form-input
            id="name"
            :value="nameFilter"
            @input="(val) => $emit('update:nameFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Adres</label>
          <b-form-input
            id="address"
            :value="addressFilter"
            @input="(val) => $emit('update:addressFilter', val)"
          />
        </b-col>
      </b-row>
    </div>
  </filters>
</template>

<script>
import {
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Filters from '@/views/components/Filters.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    Filters,
  },
  props: {
    nameFilter: {
      type: [String, null],
      default: null,
    },
    addressFilter: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
