import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Api from '@/libs/api'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'newsletter', label: '' },
    { key: 'name', label: 'Voor / achternaam', sortable: true },
    { key: 'address', sortable: true },
    { key: 'phoneNumber' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const searchQuery = ref('')
  const nameFilter = ref('')
  const addressFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, nameFilter], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    Api.fetch('customers', {
      itemsPerPage: perPage.value,
      page: currentPage.value,
      search_query: [searchQuery.value],
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(data => {
        callback(data['hydra:member'])
        totalUsers.value = data.data['hydra:totalItems']
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    nameFilter,
    addressFilter,
    refListTable,

    refetchData,

    searchQuery,
  }
}
